<template>
	<div>
		<TablesDefault
			v-model:query="computedQuery"
			v-model:currentFilterValues="currentFilterValues"
			:headers="headers"
			:records="store.instances[instanceLabel].queryData"
			:count="store.activeCount[instanceLabel]"
			:use-search="useSearch"
			:per-page="store.instances[instanceLabel].perPage"
			:current-page="store.instances[instanceLabel].currentPage"
			:current-sort="store.instances[instanceLabel]?.sort"
			:has-next-page="store.hasNextPage(instanceLabel)"
			:is-loading="store.showLoading(instanceLabel)"
			:has-completed-first-search="store.instances[instanceLabel]?.hasCompletedFirstSearch"
			@previous="store.previousPage(instanceLabel)"
			@next="store.nextPage(instanceLabel)"
			@sort="store.setSort(instanceLabel, {[$event[0]]: $event[1]})"
		>
			<template #default>
				<MoversRow v-for="record in store.instances[instanceLabel].queryData" :key="record.id" :record="record" @click="store.openPanel(record.id)" />
			</template>
		</TablesDefault>
	</div>
</template>
<script setup lang="ts">

import {computed, onBeforeMount, ref, watch} from "vue";
import type { TableHeaders, UserRecord } from "~/utils/types";
import {useMovers} from "~/stores/movers";

const props = defineProps({
	query: {
		type: String,
		required: false,
		default: ""
	},
	instanceLabel: {
		type: String,
		required: true
	},
	useSearch: {
		type: Boolean,
		required: false,
		default: true
	}
});

const store = useMovers();

const openRecord = ref(null as UserRecord | null);
const emit = defineEmits(['update:query']);

const computedQuery = computed({
	get() {
		return props.query;
	},
	set(value) {
		emit('update:query', value);
	}
});

const currentFilterValues = computed({
	get() {
		return {
			managed: store.instances[props.instanceLabel].queryFacets.managed ?? undefined
		}
	},
	set(value) {
		store.setFacet(props.instanceLabel, 'managed', value.managed);
	}
});

const headers: TableHeaders = [
	{
		label: "First name",
		id: "firstname"
	},
	{
		label: "Last name",
		id: "lastname"
	},
	{
		label: "Email",
		id: "email"
	},
	{
		label: "Phone",
		id: "phone"
	}
];

onBeforeMount(() => {
	store.search(props.instanceLabel, props.query);
});

watch(() => props.query, () => {
	store.searchAndResetPage(props.instanceLabel, props.query)
});

watch(() => store.currentRecord, () => {
	if (store.currentRecord) {
		openRecord.value = store.currentRecord;
	}
});

</script>
